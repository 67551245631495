import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, CircularProgress, Alert, Box } from '@mui/material';

const LastLuckyPerson = () => {
    const [lastWinner, setLastWinner] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the last winner when the component mounts
    useEffect(() => {
        const fetchLastWinner = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_HOST}/last-winner`, {
                    headers: {
                        'Cache-Control': 'no-cache',
                    },
                });
                setLastWinner(response.data); 
                setLoading(false);
            } catch (error) {
                setError('Error fetching last winner.');
                setLoading(false);
            }
        };

        fetchLastWinner();
    }, []); 

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 16,  
                right: 16,   
                zIndex: 9999 
            }}
        >
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Alert severity="error">{error}</Alert>
            ) : lastWinner ? (
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography variant="h6" component="div" gutterBottom>
                            Last Lucky Person
                        </Typography>
                        <Typography variant="body1">
                            <strong>Name:</strong> {lastWinner.name}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Date:</strong> {lastWinner.date}
                        </Typography>
                    </CardContent>
                </Card>
            ) : (
                <Alert severity="info">No winners yet.</Alert>
            )}
        </Box>
    );
};

export default LastLuckyPerson;
