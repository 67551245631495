import React from 'react';
import NameWheelContainer from './components/NameWheelContainer';
import PageTitle from './components/PageTitle'
import LastLuckyPerson from './components/LastLuckyPerson';

function App() {
    return (
        <div className="App">
            
            <PageTitle />
            <LastLuckyPerson />
            <NameWheelContainer />
        </div>
    );
}

export default App;
