import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const PageTitle = () => {
  return (
    <Box
        display="flex"
        justifyContent="center"
        textAlign="center"
        paddingTop="2rem"
        paddingBottom="6rem" 
    >
        <Typography variant="h1" component="h1" sx={{ fontSize: '4rem', fontWeight: 'bold' }}>
            Team Ampere's <span style={{color:'red'}}>Lucky</span> Wheel
        </Typography>
    </Box>
  );
};

export default PageTitle;
