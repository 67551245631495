import React, { useState } from 'react';
import NameWheel from './NameWheel';
import NameInput from './NameInput';
import NameList from './NameList';
import WinnerFrequencyChart from './WinnerFrequencyChart'; 
import { Grid, Box } from '@mui/material';
import axios from 'axios';

const NameWheelContainer = () => {
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [data, setData] = useState([]);

    const handleSpinClick = () => {
        if (data.length > 0) {
            const newPrizeNumber = Math.floor(Math.random() * data.length);
            setPrizeNumber(newPrizeNumber);
            setMustSpin(true);
        } else {
            alert('No names on the wheel to spin!');
        }
    };

    const addNameToWheel = (name) => {
        const newColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
        const newEntry = {
            option: name,
            style: { backgroundColor: newColor, textColor: '#ffffff' },
        };
        setData((prevData) => [...prevData, newEntry]);
    };

    const deleteNameFromWheel = (index) => {
        setData((prevData) => prevData.filter((_, i) => i !== index));
    };

    const onStopSpinning = () => {
        setMustSpin(false);
        const winner = data[prizeNumber].option;
        alert(`The winner is: ${winner}`);
        const date = new Date().toLocaleString();

        axios.post(`${process.env.REACT_APP_BACKEND_HOST}/add-winner`, { winnerName: winner, date }).then((response) => {
            console.log(response.data);
        }).catch((error) => {
            console.error('There was an error adding the winner!', error);
        });
    };

    const shuffleData = () => {
        setData((prevData) => {
            let shuffledData = [...prevData];
            for (let i = shuffledData.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [shuffledData[i], shuffledData[j]] = [shuffledData[j], shuffledData[i]];
            }
            return shuffledData;
        });
    };

    return (
        <Grid container spacing={4}>
            {/* Name Wheel and Inputs */}
            <Grid item xs={12} md={8}>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <NameInput addNameToWheel={addNameToWheel} />
                    <NameWheel
                        data={data}
                        mustSpin={mustSpin}
                        prizeNumber={prizeNumber}
                        handleSpinClick={handleSpinClick}
                        onStopSpinning={onStopSpinning}
                        shuffleData={shuffleData}
                    />
                    <NameList names={data} deleteNameFromWheel={deleteNameFromWheel} />
                </Box>
            </Grid>

            {/* Winner Frequency Chart */}
            <Grid item xs={12} md={4}>
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <WinnerFrequencyChart />
                </Box>
            </Grid>
        </Grid>
    );
};

export default NameWheelContainer;
