import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Box, CircularProgress, Typography, Alert, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const WinnerFrequencyChart = () => {
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [range, setRange] = useState('week'); 


    useEffect(() => {
        const barColors = [
            'rgba(75, 192, 192, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(199, 199, 199, 0.6)',
        ];

        const fetchFrequencyData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_HOST}/winner-frequency?range=${range}`);
                const frequencyData = response.data;
                const labels = Object.keys(frequencyData); 
                const data = Object.values(frequencyData); 

            
                const backgroundColors = labels.map((_, index) => barColors[index % barColors.length]);

                setChartData({
                    labels,
                    datasets: [
                        {
                            label: 'Number of Wins',
                            data: data,
                            backgroundColor: backgroundColors,
                            borderColor: backgroundColors.map(color => color.replace('0.6', '1')),
                            borderWidth: 1,
                        },
                    ],
                });
                setLoading(false);
            } catch (error) {
                setError('Error fetching winner frequency data.');
                setLoading(false);
            }
        };

        fetchFrequencyData();
    }, [range]); 


    const handleRangeChange = (event) => {
        setRange(event.target.value);
    };

    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Box sx={{ width: '80%', margin: 'auto', mt: 4 }}>
            <Typography variant="h5" gutterBottom align="center">
                Winner Frequency
            </Typography>


            <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel id="range-select-label">Select Time Range</InputLabel>
                <Select
                    labelId="range-select-label"
                    id="range-select"
                    value={range}
                    label="Select Time Range"
                    onChange={handleRangeChange}
                >
                    <MenuItem value="week">Last Week</MenuItem>
                    <MenuItem value="month">Last Month</MenuItem>
                    <MenuItem value="all">All Time</MenuItem>
                </Select>
            </FormControl>

 
            {chartData && (
                <Bar
                    key={range} 
                    data={chartData}
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: `Winner Frequency (${range === 'week' ? 'Last Week' : range === 'month' ? 'Last Month' : 'All Time'})`,
                            },
                        },
                    }}
                />
            )}
        </Box>
    );
};

export default WinnerFrequencyChart;
