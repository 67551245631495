import React from 'react';
import { Wheel } from 'react-custom-roulette';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useEffect } from 'react';

const NameWheel = ({ data, mustSpin, prizeNumber, handleSpinClick, onStopSpinning, shuffleData }) => {
    useEffect(() => {
        console.log("mustSpin has changed:", mustSpin);
    }, [mustSpin]);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                data={data.length ? data : [{ option: 'No Data', style: { backgroundColor: 'gray', textColor: 'black' } }]}
                backgroundColors={['#3e3e3e', '#df3428']}
                textColors={['#ffffff']}
                onStopSpinning={onStopSpinning}
            />
            <Button 
                variant="contained" 
                onClick={shuffleData} 
                sx={{ marginTop: '1rem' }}>
                Shuffle Names
            </Button>

            <Button
                variant="contained"
                color="success"
                size="large"
                onClick={handleSpinClick}
                sx={{
                    marginTop: '1.5rem',
                    padding: '0.75rem 2rem',
                    fontSize: '1.25rem',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                    '&:hover': {
                        backgroundColor: 'green',
                        transform: 'scale(1.05)', // Slightly enlarge on hover
                    },
                }}
            >
                Start Spinning
            </Button>
        </Box>
    );
}; 

export default NameWheel;
