import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const NameInput = ({ addNameToWheel }) => {
    const [name, setName] = useState('');

    const handleAddClick = () => {
        if (name.trim() !== '') {
            addNameToWheel(name);
            setName(''); 
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') { 
            event.preventDefault(); 
            handleAddClick();
        }
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="center" marginBottom="2rem">
            <TextField
                label="Enter Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyDown={handleKeyPress}
                sx={{ marginRight: '1rem' }}
            />
            <Button variant="contained" onClick={handleAddClick}>
                Add
            </Button>
        </Box>
    );
};

export default NameInput;
